body {
  background-color: #e7e8f3;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Inter",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.inter-bold {
  font-family: "Inter Bold", sans-serif;
  font-weight: 700;
}

.h1 {
  font-family: "Inter Bold", sans-serif;
  font-weight: 700;
}

.valores {
  font-family: "Inter Extra Bold", sans-serif;
  font-weight: 800;
}

.salas {
  font-family: "Inter", sans-serif;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-text {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.mw-20{ max-width: 20%; }

.grid-box-box:hover {
  /* increase size by 10% */
  transform: scale(1.15);
  transition: 0.5s ease-in-out;
}