/* Default styles for the sidebar */
.navbar {
  align-items: center;
  width: 80px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 0;
  z-index: 9999; /* Set to a high value */
}

/* Styles for the upperbar on small screens */
@media (max-width: 480px) {
  .navbar {
    width: 100%;
    height: 60px;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }
}

.sidebar-itens {
  position: fixed;
  top: 10px;
  margin: 13px;
}

/* Styles for the upperbar items on small screens */
@media (max-width: 480px) {
  .sidebar-itens {
    scale: 0.8;
    position: static;
    /*put navbar in front of everything*/
    z-index: 10000;
    margin: 0;
  }
}

.sidebar-itens *:hover {
  /* increase size by 10% */
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}

.sidebar-item2 {
  top: 80px;
}

.sidebar-item3 {
  top: 150px;
}

.sidebar-item4 {
  top: 220px;
}

.sidebar-item5 {
  top: 290px;
}


.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar2-open {
  transform: translateX(0);
}

.navbar2-closed {
  transform: translateX(350px);
}

.navbar2 {
  align-items: center;
  width: 80px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px 0;
  transition: 0.3s ease-in-out;
}

@media (max-width: 480px) {
  .navbar2 {
    /*hide the item*/
    display: none;
  }
}

.nav-text a:hover {
  background-color: #1a83ff;
}

span {
  margin-left: 16px;
}
