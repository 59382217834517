.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  color: #000000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

g .value-text text {
  fill: #000000 !important;
  text-shadow: none !important;
}

tr .align-me {
  display: flex;
  align-items: center;
}


@media (min-width: 480px) {
  .container {
    margin-right: 10%;
    margin-left: 10%;
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .container {
    max-width: 95%;
  }
}

.pie-chart {
  width: 70%;
  height: 70%;
  /*center the graph inside the gridbox*/
  justify-content: center;
  align-items: center;
  display:inline-block;  
  min-width: 300px; /* change this value to your desired minimum width */
  min-height: 300px; /* change this value to your desired minimum height */
}



.expanded-1,
.expanded-2,
.expanded-3,
.expanded-4,
.expanded-5,
.expanded-6,
.expanded-7,
.expanded-8,
.expanded-9 {
  /*put in front of everything*/
  z-index: 100;
  position: fixed;
  /* turn all itens in background a little bit darker*/
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.expanded-form {
  /*put in front of everything*/
  z-index: 100;
  position: fixed;
  /* turn all itens in background a little bit darker*/
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* lower the width without lower the total background*/
  margin: auto;

  /* center the form*/
  display: flex;
  justify-content: center;
  align-items: center;
}
