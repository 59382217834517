.table-styled-class-dark {
  background-color: #3C4042;
  color: #FCFCFC;
}

.table-styled-class-dark:hover {
  background-color: #3C4042;
  color: #FCFCFC;
}

.table-styled-class-light {
  background-color: #FFFFFF;
  color: #2C2C2C;
}

.table-styled-class-light:hover {
  background-color: #FFFFFF;
  color: #2C2C2C;
}