@media (max-width: 480px) {
  .gridbox {
    width: 100%;
  }
}

@media (min-width: 481px) {
  .gridbox {
    width: 200px;
  }
}
